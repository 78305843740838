body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

.main{
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo{
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
    box-shadow: 
    3px 3px 5px rgba(0, 0, 0, 0.1), 
    -3px 3px 5px rgba(0, 0, 0, 0.1), 
    3px -3px 5px rgba(0, 0, 0, 0.1), 
    -3px -3px 5px rgba(0, 0, 0, 0.1);
    margin-top: 2%;
    background-color: white;
    position: absolute;
    
    img{
      width:5rem;
    }
  }
}

.f-container-light{
  a{
    text-decoration: none;
    color: #0055ff;
  }
  align-items: center;
  text-align: center;
  width: 27rem;
  padding: 3rem;
  margin-top: 5%;
  margin-left: 37%;
  margin-right: 37%;
  box-shadow: 
    3px 3px 5px rgba(0, 0, 0, 0.1), 
    -3px 3px 5px rgba(0, 0, 0, 0.1), 
    3px -3px 5px rgba(0, 0, 0, 0.1), 
    -3px -3px 5px rgba(0, 0, 0, 0.1);
  border-radius: 0.4rem;
  height: 30rem;

  h3{
    margin: 2rem;
  }
  .input{
    display: flex;
    flex-direction: column;
    
    input{
      margin-bottom: 1.5rem;
      border: none;
      box-shadow: 
      3px 3px 5px rgba(0, 0, 0, 0.1), 
      -3px 3px 5px rgba(0, 0, 0, 0.1), 
      3px -3px 5px rgba(0, 0, 0, 0.1), 
      -3px -3px 5px rgba(0, 0, 0, 0.1);
      border-radius: 3rem;
      padding: 1rem;
      transition: .3s;
    }

    input:hover{
      transform: scale(1.05);
    }

    input:focus{
      outline: none;
    }
  }
  .submit{
    width: 100%;
    height: 3rem;
    border-radius: 3rem;
    background-color: black;
    border: 2px solid black;
    color: white;
    margin-top: 1rem;
    transition: .3s;
    font-size: medium;
    font-weight: bold;
  }
  
  .submit:hover{
    background-color: white;
    color: black;
  }
  
  .rfp{
    display: flex;
    justify-content: space-between;
  }

}

a{
  text-decoration: none;
  color: #0055ff;
}

hr{
  margin: 3rem;

}

.google{
  background-image: url(./images/google.png);
  background-repeat: no-repeat;
  background-size: 2rem;
  background-position-y:center;
  background-color: white;
  border: none;
  box-shadow:3px 3px 5px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 3rem;
  border-radius: none;
  font-size: medium;
  font-weight: bold;
  transition: .3s;
}

.google:hover{
  box-shadow: 
  3px 3px 5px rgba(0, 0, 0, 0.1), 
  -3px 3px 5px rgba(0, 0, 0, 0.1), 
  3px -3px 5px rgba(0, 0, 0, 0.1), 
  -3px -3px 5px rgba(0, 0, 0, 0.1);
  transform: scale(1.1);

}

.pass{
  display: inline-block;
  position: relative;

  input{
    width: 93%;
  }

  .password-toggle-icon{
    position: absolute;
    top: 34%;
    right: 20px;
    transform: translateY(-50%);
    cursor: pointer;
    float: right;
  }
}
.Cpass{
  display: inline-block;
  position: relative;

  input{
    width: 93%;
  }

  .Cpassword-toggle-icon{
    position: absolute;
    top: 34%;
    right: 20px;
    transform: translateY(-50%);
    cursor: pointer;
    float: right;
  }
}


.oAuth{
  display: inline-block;
}



@media (max-width: 767px) {
  .f-container-light{
    margin: 2rem;
    width: 80%;
    box-shadow: none;
  }
  
  .s-container-light{
    margin: 2rem;
    width: 80%;
    box-shadow: none;
  }

  .logo{
    margin-top: 1%;
  }
}

.s-container-light{
  a{
    text-decoration: none;
    color: #0055ff;
  }
  align-items: center;
  width: 27rem;
  padding: 3rem;
  margin-top: 5%;
  margin-left: 37%;
  margin-right: 37%;
  margin-bottom: 5%;
  box-shadow: 
    3px 3px 5px rgba(0, 0, 0, 0.1), 
    -3px 3px 5px rgba(0, 0, 0, 0.1), 
    3px -3px 5px rgba(0, 0, 0, 0.1), 
    -3px -3px 5px rgba(0, 0, 0, 0.1);
  border-radius: 0.4rem;
  height: 40rem;

  h3{
    margin: 2rem;
  }
  .input{
    display: flex;
    flex-direction: column;
    
    input{
      margin-bottom: 1.5rem;
      border: none;
      box-shadow: 
      3px 3px 5px rgba(0, 0, 0, 0.1), 
      -3px 3px 5px rgba(0, 0, 0, 0.1), 
      3px -3px 5px rgba(0, 0, 0, 0.1), 
      -3px -3px 5px rgba(0, 0, 0, 0.1);
      border-radius: 3rem;
      padding: 1rem;
      transition: .3s;
    }

    input:hover{
      transform: scale(1.05);
    }

    input[type=checkbox]{
      box-shadow: none;
    }
  }
  .submit{
    width: 100%;
    height: 3rem;
    border-radius: 3rem;
    background-color: black;
    border: 2px solid black;
    color: white;
    margin-top: 1rem;
    transition: .3s;
    font-size: medium;
    font-weight: bold;
  }
  
  .submit:hover{
    background-color: white;
    color: black;
  }
  
  .rfp{
    display: flex;
    justify-content: space-between;
  }

}

.f-main{
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo{
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
    box-shadow: 
    3px 3px 5px rgba(0, 0, 0, 0.1), 
    -3px 3px 5px rgba(0, 0, 0, 0.1), 
    3px -3px 5px rgba(0, 0, 0, 0.1), 
    -3px -3px 5px rgba(0, 0, 0, 0.1);
    margin-top: 2%;
    background-color: white;
    position: absolute;
    
    img{
      width:5rem;
    }
  }
  p{
    color: grey;
    
  }

  .back{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 1rem 0 0 .5rem;

    :hover{
      text-decoration: underline;
    }

    a{
      text-decoration: none;
      color: black;
    }

    h4{
      font-weight:400;
      margin-left: .5rem;
    }
  }
}


/* navbar */

.nav-main{
  padding-top: 1rem;
  width: 100%;
  background-color: rgb(255, 255, 255);
  box-sizing: border-box;
  box-shadow: 
  3px 3px 5px rgba(0, 0, 0, 0.1), 
  -3px 3px 5px rgba(0, 0, 0, 0.1), 
  3px -3px 5px rgba(0, 0, 0, 0.1), 
  -3px -3px 5px rgba(0, 0, 0, 0.1);

  .nav-container{
    width: 100%;
    display: flex;
    justify-content:space-between;
    height: 4.5rem;

    .left{
      display: flex;
      height: 4.5rem;
      width: 4.5rem;
      justify-content: space-between;
      background-color: white;

      .bars{
        height: 2rem;
        margin-left: 1rem;
        margin-right: 1rem;
        padding-top: 1rem;

      }
    
      .logo{
        height: 4rem;
      }

      
    }
    
    .center{
      h1{
        font-weight: lighter;
        text-align: center;
        margin-top: .4rem;
        visibility: hidden;
      }
    }
    
    .right{
      display: flex;
      justify-content: space-between;

      .dropdown-profile{
        .profile{
          height: 1.5rem;
          margin-left: 2rem;
          padding-top: 1rem;
          margin-right: 2rem;
          position: relative;
          display: inline-block;
        }
        .dropdown{
          display: none;
          position: absolute;
          top: 4rem;
          right: 0;
          background-color: #fff;
          border: 1px solid #ddd;
          border-radius: 4px;
          box-shadow: 0 2px 5px rgba(0,0,0,0.2);
          padding: 1rem;

          .link{
            display: flex;
            padding: .1rem;
            

            .icon{
              margin-top: .8rem;
            }
          }
    
          a{
            display: block;
            padding: 10px;
            color: #333;
            text-decoration: none;
          }
        }
      }

      .dropdown-profile:hover{
        .dropdown{
          display: block;

          .link:hover{
            background-color: rgba(128, 128, 128, 0.111);
          }
        }
      }
      
    
      .mode{
        height: 1.5rem;
        padding-top: 1rem;

      }

    }


    
  }

  .nav-sidebar{
    position: absolute;
    margin-top: 5rem;
    background-color: rgba(255, 255, 255);   
    height: 100vh; 
    width: 15rem;
    margin-left: 0;

    .side-main{
      width: 100%;
      padding-top: 2rem;

      height: 100%; 

      .side-links{
        height: 100vh;
        padding: 0;
        a{
          color: black;
        }
        ul{
          width: 100%;
          list-style: none;
          padding-left: 0 ;
    
          .side-link{
            display: flex;
    
            img{
              margin: 1rem;
              width: 1.5rem;
              height: 1.5rem;
            }
          }
          li{
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 1rem 0 1rem 0;
            font-size: 1.25rem;
          }
    
          li:hover{
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.hidden{
  display: none;
  visibility: hidden;
}


/* dashboard */

.d-main{
  display: flex;
  margin: 0;
  padding: 0;

  .d-board{
    display: flex;
    flex-direction: column;
    width: 100%;
    

    .d-count{
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: space-between;
      padding: 2rem;
      height: auto;
      max-width: 80rem;


      .dc{
        height: 10rem;
        width: 12rem;
        padding: 1rem;
        background: linear-gradient(180deg, #333 0%, #000 100%);       
         box-shadow: 
          3px 3px 5px rgba(0, 0, 0, 0.1), 
          -3px 3px 5px rgba(0, 0, 0, 0.1), 
          3px -3px 5px rgba(0, 0, 0, 0.1), 
          -3px -3px 5px rgba(0, 0, 0, 0.1);
        color: white;
        border-radius: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1 calc(20%-10px);
        text-align: center;
        box-sizing: border-box;
        transition: .3s;

        h1{
          
          visibility: hidden;
          position: absolute;
        }

        h4{
          font-size: 1.7rem;
        }        
      }

      
    }
    .addc{
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .add{
        color: white;
        height: 3.5rem;
        width: 7rem;
        margin-right: 3rem;
        background: none;
        background: linear-gradient(180deg, #333 0%, #000 100%);       
        box-shadow: 
        3px 3px 5px rgba(0, 0, 0, 0.1), 
        -3px 3px 5px rgba(0, 0, 0, 0.1), 
        3px -3px 5px rgba(0, 0, 0, 0.1), 
        -3px -3px 5px rgba(0, 0, 0, 0.1);
        border-radius: .8rem;
        border: none;
        font-weight: bold;
        transition: .4s;

        
        svg{
          visibility: hidden;
          position: absolute;
        }
      }

      .add:focus{
        outline: none;
      }

    }
    .add:hover{
      transform: scale(1.2);

    }

    .d-table{
      margin: 8rem 0rem 2rem 3rem;
      align-items: center;
      display: flex;
      justify-content: center;

      table {
        width: 100%;
        border-collapse: collapse;
        margin: 20px 0;

        input,select,option{
          height: 2rem;
          border: none;
          border-radius: .4rem;
          padding: .4rem;
          border: 1px solid #ddd;
          box-shadow: 0 2px 5px rgba(0,0,0,0.2);
        }
      }
    
      th, td {
          padding: 12px;
          text-align: left;
          border-bottom: 1px solid #ddd;
      }
    
      th {
          background-color: #f4f4f4;
          font-weight: bold;
      }
    
      tr:nth-child(even) {
          background-color: #f9f9f9;
      }

      tr{
        transition: .4s;

      }
      
      tr:hover {
        background-color: #f1f1f1;
      }
      
      button {
          background-color: black;
          color: white;
          border: none;
          padding: 8px 16px;
          text-align: center;
          text-decoration: none;
          display: inline-block;
          font-size: 14px;
          margin: 4px 2px;
          cursor: pointer;
          border-radius: 4px;
      }
      
      button:hover {
          background-color: white;
          color: black;
      }
    

      
    }
  }
}

/* formpage */

.formpage-main{
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.form-container-light{
  a{
    text-decoration: none;
    color: #0055ff;
  }
  align-items: center;
  text-align: center;
  width:80%;
  padding: 4rem;
  margin-top: 5%;
  margin-left: 20%;
  margin-right: 20%;
  margin-bottom: 3rem;
  box-shadow: 
    3px 3px 5px rgba(0, 0, 0, 0.1), 
    -3px 3px 5px rgba(0, 0, 0, 0.1), 
    3px -3px 5px rgba(0, 0, 0, 0.1), 
    -3px -3px 5px rgba(0, 0, 0, 0.1);
  border-radius: 0.4rem;
  height: auto;

  h3{
    margin: 2rem;
  }
  
  .input{
    display: flex;
    flex-direction: column;
    
    label{
      display: flex;
      float: left;
      margin-bottom: 1rem;
    }
    
    input,select{
      margin-bottom: 1.5rem;
      border: none;
      border-radius: 1rem;
      box-shadow: 
      3px 3px 5px rgba(0, 0, 0, 0.1), 
      -3px 3px 5px rgba(0, 0, 0, 0.1), 
      3px -3px 5px rgba(0, 0, 0, 0.1), 
      -3px -3px 5px rgba(0, 0, 0, 0.1);
      padding: 1rem;
      transition: .3s;

      
    }

    input:hover,select:hover{
      transform: scale(1.05);
    }

    input:focus,select:focus{
      outline: none;
    }

    .tdesc{
      height: 5rem;
    }
  }
  
  .input-date{
    display: flex;
    justify-content: space-between;
  }

  .input-counter{
    display: flex;
    flex-direction: column;

    .value{
      display: flex;
      justify-content: space-around;

      button{
        border: none;
        background-color: white;
        height: 3rem;
        width: 3rem;
        border-radius: 50%;
        box-shadow: 
        3px 3px 5px rgba(0, 0, 0, 0.1), 
        -3px 3px 5px rgba(0, 0, 0, 0.1), 
        3px -3px 5px rgba(0, 0, 0, 0.1), 
        -3px -3px 5px rgba(0, 0, 0, 0.1);
        transition: .4s;
      }

      button:hover{
        transform: scale(1.2);
      }
    }
  }

  .submit{
    width:5rem;
    height: 3rem;
    border-radius: 1rem;
    border: 2px solid black;
    background-color: black;
    color: white;
    margin-bottom: 1rem;
    float: left;
    transition: .3s;
    font-size: medium;
    font-weight: bold;
  }
  
  .submit:hover{
    background-color: white;
    color: black;
  }
  
}

/* sidebar */

.s-main{
  width: 100%;
  padding-top: 2rem;
  box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.5);   
  height: 100%; 

  .s-links{
    height: 100vh;
    padding: 0;
    
    a{
      color: black;
    }
    
    ul{
      width: 100%;
      list-style: none;
      padding-left: 0 ;

      .s-link{
        display: flex;

        img{
          margin: 1rem;
          width: 1.8rem;
          height: 1.7rem;

        }
      }
      
      li{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 1rem 0 1rem 0;
        font-size: 1.25rem;
      }

      li:hover{
        text-decoration: underline;
      }
    }
  }
}

/* home */

.h-container{
  height: 100vh;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}
.h-main{
  display: flex;

  .h-side{
    width: 15%;
  }

  .h-d{
    margin:1rem;
    width: 85%;
  }
}

/* task details page */

.t-main{
  .t-btns{
    button{
      background-color: black;
      color: white;
      margin: 1rem;
      height: 2rem;
      border-radius: .2rem;
      border: 2px solid black;
      transition: .4s;
    }

    button:hover{
      transform: scale(1.2);
    }
  }

  .chart{
    text-align: center;

    .graph{
      position: inherit;
      margin: 10rem;

    }
    .select{
      float: left;
  
      select{
        margin-top: 1rem;
        display: flex;
        justify-content: center;
        border: none;
        height: 2rem;
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
        transition: .4s ;
      }

      select:hover{
        transform: scale(1.1);
      }
    }

    .only{
      text-align: center;
      display: flex;
      justify-content: center;

      .responsiveContainer{
        padding: 10rem;
      }

      select{
        display: flex;
        justify-content: center;
        border: none;
        height: 2rem;
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);    
      }
  
      select:focus{
        outline: none;
      }
    }
  }
}

.truncated-text {
  white-space: nowrap;         
  overflow: hidden;            
  text-overflow: ellipsis;     
  max-width: 5rem;   
  transition: transform 0.3s;
}

.truncated-text:hover{
  position: absolute;
  background-color: white;
  white-space: normal;
  overflow: visible;
  text-overflow: clip;
  max-width: none;
  transform: scale(1.2);   
}


/* operations */

.o-main{
  text-align: center;
  .chart{
    display: flex;
    justify-content: center;
    
    .graph{
      position: inherit;
    }
  }

  .only{
    float: left;

    select{
      margin-top: 1rem;
      display: flex;
      justify-content: center;
      border: none;
      height: 2rem;
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
    }
  }
}

@media(max-width:1080px){
  .input-date{
    display: flex;
    flex-direction: column;

    input{
      width: 100%;
    }
  }

  .form-container-light{
    width: 100%;
    margin: 0;
    padding: 3rem;
    box-shadow: none;

    input,select{
      width: 100%;
    }

  }
}

.responsiveContainer{
  padding: 1rem;
  height: 100vh;

  .lineChart{
    height: 100vh;
    margin-bottom: 5rem;
  }
}










